.dmm-side-image {
    width: 80%;
    height: 25vh;
}

.dmm-facts {
    color: #40C1BF;
}

.dmm-p {
    text-align: justify;
}



.dmm-img-width {
    width: 100%;
}

.dmm-h1 {
    font-size: xx-large;
}

.dmm-h2 {
    font-size: x-large;
}

.dmm-h3 {
    font-size: large;
}

.dmm-h4 {
    font-size: medium;
}

/* Hide mobile-only content on desktop devices */
.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

/* Show mobile-only content on mobile devices */
@media only screen and (max-device-width: 992px) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .dmm-mobile-pic img {
        width: 100%;
        height: 90vh;
        border-radius: 20px;
    }

  

    .dmm-img-width {
        width: 100%;
    }

    .dmm-h1 {
        font-size: x-large;
    }

    .dmm-h2 {
        font-size: large;
    }

    .dmm-h3 {
        font-size: medium;
    }

    .dmm-h4 {
        font-size: medium;
    }
}