.gauri-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
  }
  
  .gauri-img-width{
    width: 100%;
  }
  
  .gauri-gs-background{
      background-image: url(/public/images/gs-background.webp);
      background-repeat: no-repeat;
      background-size: 100%;
  }
  
  .rotate {
      animation: rotate-box 1s alternate infinite;
  
  }
  
  @keyframes rotate-box {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(30deg);
    }
  }
  
  
      .gauri-text-content {
      margin: 50px;
        padding-left: 50px;
        padding-right: 50px;
        
  
        text-align: center;
      }
  
      .gauri-highlight {
        color: #00afef;
        font-weight: bold;
      }
  
      @media only screen and (max-width: 768px) {
        .gauri-img-width {
          width: 100%;
        }
  
        .first_section {
          top: 0px;
        }
  
        .image-leaf1,
        .image-leaf2 {
          display: none;
        }
  
        .col-md-6.first_section {
          order: 2;
        }
  
        .col-md-6 {
          order: 1;
        }
  
        .gauri-text-content {
          padding: 10px;
          margin: 0;
          text-align: justify;
        }
  
        .gauri-h1 {
          font-size: 24px;
          /* adjust the value as needed */
        }
  
        .gauri-h2 {
          font-size: 22px;
          /* adjust the value as needed */
        }
        .image-leaf1, .image-leaf2 {
      width: 20%; /* adjust the value as needed */
      height: auto;
    }
    .gauri-gs-background{
      background-image: url(/public/images/gs-background.webp);
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  }
  
  @media only screen and (min-width: 1200px) {
    .gauri-text-content {
      font-size: 22px;
    }
    .gauri-h1, .gauri-h2{
      font-weight: bold;
    }
  }