.juris-image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  
  
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .juris-image-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .juris-container {
      flex-direction: column;
    }
    .left-section {
      flex: 1;
    }
    .juris-image-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }