.dmg-img-width {
    width: 100%;
}

.dmg-h1{
    font-size: xx-large;
}
.dmg-h2{
    font-size: x-large;
}
.dmg-h3{
    font-size: large;
}
.dmg-h4{
    font-size: medium;
}


.desktop-only {
display: block;
}

.mobile-only {
display: none;
}

@media only screen and (max-device-width: 992px){
.desktop-only {
display: none;
}

.mobile-only {
display: block;
}

.mobile-pic img {
width: 100%;
height: 90vh;
border-radius: 20px;
}

}